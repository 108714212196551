.App {
  text-align: center;
}

nav ul {
  list-style: none;
}

nav ul li {
  display: inline-block;
  padding: 10px;
}
