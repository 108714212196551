

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  text-align: left;
}


input[type=text]  {
  border-radius: 5px;
  line-height: 30px;
  height: 40px;
  min-width: 80%;
  border: 1px solid #EEEEEE;
  padding: 2px 4px;
}
  select {
  border-radius: 5px;
  line-height: 30px;
  height: 40px;
  min-width: 220px;
  border: 1px solid #EEEEEE;
  padding: 2px 4px;
}

textarea {
  min-width: 80% !important;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #EEE;
  border-radius: 5px;
  background-color: #f8f8f8;
  resize: none;
}
.btnaction {
  background-color: #04AA6D;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 15px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}
input.priceinput{
  min-width: 100px !important;
}
header {
  background-color: #096946 !important;
}
